import Swup from 'swup';
import Masonry from 'masonry-layout';
import jQueryBridget from 'jquery-bridget';
import $ from 'jquery';

//페이지 전환(swup 플러그인 실행)
var swup = new Swup();

// make Masonry a jQuery plugin
jQueryBridget('masonry', Masonry, $); // https://masonry.desandro.com/extras.html

// 프로젝트 포스트(프로젝트 포스트 masonry 플러그인 호출용)
function postGrid() {
  if (document.querySelector('.post-inner') != null) {
    var $grid = $('.post-inner').masonry({
      itemSelector: '.grid-item',
      columnWidth: '.grid-sizer',
      percentPosition: true,
    });
  }
}

$(function () {
  postGrid();
  document.addEventListener('swup:contentReplaced', postGrid);
});
