import '../css/scss/hnine.scss';
import $ from 'jquery';
import Swup from 'swup';

import './func/menu.js';
import './func/lazy.js';
import './func/popup.js';
import './func/resize.js';
import './func/intro.js';
import './func/scroll.js';
import './func/anim.js';
import './func/page.js';

import './lib/masonry.js';
import './lib/cookie.js';

import './lib/vendor/infinite-scroller';

import './pages/blog.js';
import './pages/blogdetail.js';
import './pages/careers.js';
import './pages/careersdetail.js';
import './pages/company.js';
import './pages/contact.js';
import './pages/tech.js';
import './pages/index.js';
import './pages/project.js';
import './pages/people.js';
import './pages/projectdetail.js';
import './pages/sub.js';

$(function () {
  //ie10이하 리다이렉트
  if (navigator.userAgent.indexOf('MSIE') >= 0) {
    window.location.href = './browser.html';
  }

  //페이지 전환(swup 플러그인 실행)
  var swup = new Swup();
});
