import Swup from 'swup';
import $ from 'jquery';
import ScrollMagic from 'scrollmagic';
import gsap, { TimelineMax, TweenMax, Linear } from 'gsap';

//주소에 따른 작업(각 페이지별 html에 클래스값 들어가기위한 스크립트, 메인에서 풀페이지 스크립트 불러오고 다른페이지에선 초기화 되도록 작업)
function careersContentReplaced() {
  if (document.querySelector('html') != null) {
    if (window.location.pathname === '/careers.html' || window.location.pathname === '/careers') {
      $('html').addClass('careers');
      $('html').removeClass('sub');
      $('html').removeClass('home');
      $('html').removeClass('company');
      $('html').removeClass('project');
      $('html').removeClass('contact');
      $('html').removeClass('tech');
      $('html').removeClass('detail');
      $('html').removeClass('career');
      $('html').removeClass('people');
      $('html').removeClass('blog');
      $('html').removeClass('blog-single');
      $('html').removeClass('intro-ing');
      $('html').removeClass('detail4');
    }
  }
}

//채용 애니메이션(채용 하단 순차 노출되는 애니메이션용)
function careersAni() {
  if (document.querySelector('.link-careers-warpper') != null) {
    $(document).ready(function () {
      var controller = new ScrollMagic.Controller();

      var tl = gsap.timeline();
      tl.staggerTo('.link-careers-warpper .box-item', 0.4, { autoAlpha: 1 }, 0.2);

      var scene = new ScrollMagic.Scene({
        triggerElement: '.link-careers-warpper',
        triggerHook: 0.9,
      })
        .setTween(tl)
        .addTo(controller);
    });
  }
}

//채용 키워드 셀렉(채용공고 리스트 키워드 current 액션용)
function jobsAction() {
  if (document.querySelector('.jobs .box-keyword a') != null) {
    $('.jobs .box-keyword a').click(function () {
      $(this).parent().parent().addClass('is-current').siblings('li').removeClass('is-current');
    });
  }
}

//채용 리스트 셀렉(채용공고 리스트 셀렉 current 액션용)
function jobsListAction() {
  if (document.querySelector('.list-select-wrapper') != null) {
    $('.list-select-wrapper a').click(function () {
      $(this).parent().addClass('is-current').siblings('li').removeClass('is-current');
    });
  }
}

$(function () {
  careersContentReplaced();
  document.addEventListener('swup:contentReplaced', careersContentReplaced);
  careersAni();
  document.addEventListener('swup:contentReplaced', careersAni);
  jobsAction();
  document.addEventListener('swup:contentReplaced', jobsAction);
  jobsListAction();
  document.addEventListener('swup:contentReplaced', jobsListAction);
});
