let basePath;

if (process.env.NODE_ENV == 'development') {
  // basePath = '/Stage';
  basePath = '';
} else {
  basePath = '';
}

export const SERVER_URL = 'https://api.hnine.com';

export const API_INQUERY = '/api/mail/inquiry';
export const API_RESUME = '/app/resume';
