import Swup from 'swup';
import $ from 'jquery';
import { checkInputValidation, checkValidationEmail, checkValidationNumber } from '../func/utils';
import { SERVER_URL, API_INQUERY } from '../contants';

//주소에 따른 작업(각 페이지별 html에 클래스값 들어가기위한 스크립트, 메인에서 풀페이지 스크립트 불러오고 다른페이지에선 초기화 되도록 작업)
function contactContentReplaced() {
  if (document.querySelector('html') != null) {
    if (window.location.pathname === '/contact.html' || window.location.pathname === '/contact') {
      console.log('includes(contact.html)');
      $('html').addClass('contact');
      $('html').removeClass('sub');
      $('html').removeClass('home');
      $('html').removeClass('project');
      $('html').removeClass('careers');
      $('html').removeClass('company');
      $('html').removeClass('tech');
      $('html').removeClass('detail');
      $('html').removeClass('career');
      $('html').removeClass('people');
      $('html').removeClass('blog');
      $('html').removeClass('blog-single');
      $('html').removeClass('intro-ing');
      $('html').removeClass('detail4');
    }
  }
}

function submitContact() {
  if (document.querySelector('.contact-main') != null) {
    $('#contact-submit').click(function (e) {
      e.preventDefault();

      let valid = checkInputValidation('#company', '회사명을 입력하세요');

      if (!valid) {
        return;
      }

      //------

      valid = checkInputValidation('#deputy', '담당자명 / 직책을 입력하세요');

      if (!valid) {
        return;
      }

      //------

      valid = checkInputValidation('#email', '이메일을 입력하세요');

      if (!valid) {
        return;
      }

      const emailInput = $('#email');

      if (!checkValidationEmail(emailInput.val())) {
        alert('올바른 이메일 주소를 입력하세요');
        emailInput.focus();
        return;
      }

      //------

      valid = checkInputValidation('#contact', '전화번호를 입력하세요');

      if (!valid) {
        return;
      }

      const contactInput = $('#contact');

      if (!checkValidationNumber(contactInput.val())) {
        alert('숫자와 -만 입력가능합니다');
        contactInput.focus();
        return;
      }

      //------

      valid = checkInputValidation('#contact', '내용을 입력하세요');

      if (!valid) {
        return;
      }

      //------

      const form = $('#contact-form');
      var formSerializeArray = form.serializeArray();
      var object = {};

      for (var i = 0; i < formSerializeArray.length; i++) {
        object[formSerializeArray[i]['name']] = formSerializeArray[i]['value'];
      }

      var json = JSON.stringify(object);

      console.log('json', json);

      const loading = $('.loading-wrapper');
      loading.addClass('active');

      $.ajax({
        cache: false,
        url: SERVER_URL + API_INQUERY,
        type: 'POST',
        data: json,
        contentType: 'application/json; charset=UTF-8',
        success: function (data) {
          loading.removeClass('active');
          if (data.code === 200) {
            console.log('success', data);
            // var jsonObj = JSON.parse(data);
            $('#contact-form').trigger('reset');
            alert('문의하신 내용이 접수되었습니다!');
          } else {
            console.log('failure', data);
            alert('문의하신 내용이 접수되지 못하였습니다!');
          }
        },
        error: function (xhr, status) {
          console.log('failure', xhr, status);

          loading.removeClass('active');
          alert('문의하신 내용이 접수되지 못하였습니다!');
        },
      });
    });
  }
}

$(function () {
  contactContentReplaced();
  document.addEventListener('swup:contentReplaced', contactContentReplaced);

  submitContact();
  document.addEventListener('swup:contentReplaced', submitContact);
});
