import Swup from 'swup';
import $ from 'jquery';

//주소에 따른 작업(각 페이지별 html에 클래스값 들어가기위한 스크립트, 메인에서 풀페이지 스크립트 불러오고 다른페이지에선 초기화 되도록 작업)
function blogdetailContentReplaced() {
  if (document.querySelector('html') != null) {
    if (window.location.href.indexOf('/blog/') > -1) {
      $('html').addClass('blog');
      $('html').removeClass('sub');
      $('html').removeClass('home');
      $('html').removeClass('project');
      $('html').removeClass('careers');
      $('html').removeClass('company');
      $('html').removeClass('tech');
      $('html').removeClass('detail');
      $('html').removeClass('people');
      $('html').removeClass('career');
      $('html').removeClass('blog-single');
      $('html').removeClass('detail4');
      $('.gnb-category .gnb-item').removeClass('is-current');
      $('html').removeClass('intro-ing');
    }
  }
}

$(function () {
  blogdetailContentReplaced();
  document.addEventListener('swup:contentReplaced', blogdetailContentReplaced);
});
