import $ from 'jquery';

//주소에 따른 작업(각 페이지별 html에 클래스값 들어가기위한 스크립트, 메인에서 풀페이지 스크립트 불러오고 다른페이지에선 초기화 되도록 작업)
function subContentReplaced() {
  if (document.querySelector('html') != null) {
    if (
      !window.location.pathname === '/index.html' &&
      !window.location.pathname === '/' &&
      !window.location.pathname === '/project.html' &&
      !window.location.pathname === '/project' &&
      !window.location.pathname === '/careers.html' &&
      !window.location.pathname === '/careers' &&
      !window.location.pathname === '/contact.html' &&
      !window.location.pathname === '/contact' &&
      !window.location.pathname === '/people.html' &&
      !window.location.pathname === '/people' &&
      !window.location.pathname === '/company.html' &&
      !window.location.pathname === '/company' &&
      !window.location.pathname === 'https://www.notion.so/e3c952b5ac6943c1bd54c33e1ef73e4a' &&
      !window.location.pathname === '/blog' &&
      !window.location.pathname.indexOf('/project/') > -1 &&
      !window.location.pathname.indexOf('/careers/') > -1 &&
      !window.location.pathname.indexOf('/blog/') > -1
    ) {
      $('html').addClass('sub');
      $('html').removeClass('home');
      $('html').removeClass('project');
      $('html').removeClass('company');
      $('html').removeClass('careers');
      $('html').removeClass('contact');
      $('html').removeClass('tech');
      $('html').removeClass('detail');
      $('html').removeClass('career');
      $('html').removeClass('people');
      $('html').removeClass('blog');
      $('html').removeClass('blog-single');
      $('html').removeClass('intro-ing');
      $('html').removeClass('detail4');
    }
  }
}

$(function () {
  subContentReplaced();
  document.addEventListener('swup:contentReplaced', subContentReplaced);
});
