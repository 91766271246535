import Swup from 'swup';
import $ from 'jquery';
import Swiper from 'swiper';
import gsap from 'gsap';

//주소에 따른 작업(각 페이지별 html에 클래스값 들어가기위한 스크립트, 메인에서 풀페이지 스크립트 불러오고 다른페이지에선 초기화 되도록 작업)
function projectdetailContentReplaced() {
  if (document.querySelector('html') != null) {
    if (window.location.href.indexOf('/project/') > -1) {
      $('html').addClass('detail');
      $('html').removeClass('sub');
      $('html').removeClass('home');
      $('html').removeClass('project');
      $('html').removeClass('careers');
      $('html').removeClass('company');
      $('html').removeClass('tech');
      $('html').removeClass('career');
      $('html').removeClass('people');
      $('html').removeClass('blog');
      $('html').removeClass('blog-single');
      $('html').removeClass('intro-ing');
    }
    if (window.location.href.indexOf('korail') > -1) {
      $('html').addClass('detail4');
    } else {
      $('html').removeClass('detail4');
    }
    if (window.location.href.indexOf('lg-sound-bar') > -1) {
      $('html').addClass('detail17');
    } else {
      $('html').removeClass('detail17');
    }
    if (window.location.href.indexOf('wenzi') > -1) {
      $('html').addClass('detail15');
    } else {
      $('html').removeClass('detail15');
    }
  }
}

//디테일 슬라이드(디테일 스와이프슬라이드 호출용)
function swiperSlide() {
  if (document.querySelector('html') != null) {
    $(document).ready(function () {
      var swiper = new Swiper('#basicSwiper', {
        slidesPerView: 'auto',
        spaceBetween: 0,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    });
  }
}

//디테일 슬라이드(디테일 슬라이드 플러그인 호출용)
function slideDetail() {
  if (document.querySelector('.box-wrapper') != null) {
    $(document).ready(function () {
      $('#slideDetail').infiniteslide({
        speed: 70,
        pauseonhover: false,
        responsive: true,
      });
      $('.slideDetail').infiniteslide({
        speed: 70,
        pauseonhover: false,
        responsive: true,
      });
    });
  }
}

// detail 29
function imgShuffle() {
  let shuffle;
  if (document.querySelector('.detail29 .hero') !== null) {
    console.log('test');
    let target = document.querySelector('.detail29 .hero source');
    let img = document.querySelector('.detail29 .hero img');
    let count = 1;
    let path = '/assets/images/portfolio/detail29/';
    shuffle = setInterval(function () {
      target.setAttribute('srcset', `${path}pc-lloyd-img-0${count}.png`);
      target.setAttribute('data-src', `${path}pc-lloyd-img-0${count}.png`);
      img.setAttribute('srcset', `${path}pc-lloyd-img-0${count}.png`);
      count++;
      if (count == 4) count = 1;
      if (!document.querySelector('.detail29 .hero')) {
        clearInterval(shuffle);
      }
    }, 1000);
  }
}

//디테일4 지도 변환
function detailMap() {
  if (document.querySelector('.map-korail') != null) {
    var tl = gsap.timeline({ repeat: -1 });
    tl.to('.bg-picture.map2', 0.5, { autoAlpha: 1 }),
      tl.to('.bg-picture.map2', 0.6, { autoAlpha: 0 }, '+=2'),
      tl.to('.bg-picture.map3', 0.6, { autoAlpha: 1 }, '-=0.2'),
      tl.to('.bg-picture.map3', 0.6, { autoAlpha: 0 }, '+=2'),
      tl.to('.bg-picture.map4', 0.6, { autoAlpha: 1 }, '-=0.2'),
      tl.to('.bg-picture.map4', 0.6, { autoAlpha: 0 }, '+=2');
  }
}

//디테일9 캐릭터 변환
function detailCharacters() {
  if (document.querySelector('.characters') != null) {
    var tl = gsap.timeline({ repeat: -1 });
    tl.to('.characters .bg-picture.num1', 0.6, { autoAlpha: 1 }),
      tl.to('.characters .bg-picture.num1', 0.6, { autoAlpha: 0 }, '+=2'),
      tl.to('.characters .bg-picture.num2', 0.5, { autoAlpha: 1 }, '-=0.2'),
      tl.to('.characters .bg-picture.num2', 0.6, { autoAlpha: 0 }, '+=2'),
      tl.to('.characters .bg-picture.num3', 0.6, { autoAlpha: 1 }, '-=0.2'),
      tl.to('.characters .bg-picture.num3', 0.6, { autoAlpha: 0 }, '+=2'),
      tl.to('.characters .bg-picture.num4', 0.6, { autoAlpha: 1 }, '-=0.2'),
      tl.to('.characters .bg-picture.num4', 0.6, { autoAlpha: 0 }, '+=2');
  }
}

//디테일 슬라이드(디테일 슬라이드 플러그인 호출용)
function slideIcon() {
  if (document.querySelector('.box-wrapper') != null) {
    $(document).ready(function () {
      if (window.innerWidth > 419) {
        $('#slideIcon').infiniteslide({
          speed: 70,
          pauseonhover: false,
          // responsive: false,
        });
      } else if (window.innerWidth <= 419) {
        $('#slideIcon').infiniteslide({
          speed: 30,
          pauseonhover: false,
        });
      }
    });
  }
}

function slideIcon1() {
  if (document.querySelector('.box-wrapper') != null) {
    $(document).ready(function () {
      $('#slideIcon1').infiniteslide({
        speed: 70,
        pauseonhover: false,
        responsive: true,
      });
    });
  }
}

function slideIcon2() {
  if (document.querySelector('.box-wrapper') != null) {
    $(document).ready(function () {
      $('#slideIcon2').infiniteslide({
        speed: 70,
        pauseonhover: false,
        responsive: true,
      });
    });
  }
}

$(function () {
  projectdetailContentReplaced();
  document.addEventListener('swup:contentReplaced', projectdetailContentReplaced);
  swiperSlide();
  document.addEventListener('swup:contentReplaced', swiperSlide);
  slideDetail();
  document.addEventListener('swup:contentReplaced', slideDetail);
  detailMap();
  document.addEventListener('swup:contentReplaced', detailMap);
  detailCharacters();
  document.addEventListener('swup:contentReplaced', detailCharacters);
  slideIcon();
  document.addEventListener('swup:contentReplaced', slideIcon);
  slideIcon1();
  document.addEventListener('swup:contentReplaced', slideIcon1);
  slideIcon2();
  document.addEventListener('swup:contentReplaced', slideIcon2);
  imgShuffle();
  document.addEventListener('swup:contentReplaced', imgShuffle);
});
