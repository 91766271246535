import $ from 'jquery';

//팝업업오픈
function popupOpen() {
  if (document.querySelector('#applyBtn') != null) {
    $('#applyBtn').click(function () {
      $('html').addClass('is-popup');
    });
  }
}

//팝업닫기
function popupClose() {
  if (document.querySelector('html') != null) {
    $('.btn-close').click(function () {
      $('html').removeClass('is-popup');
    });
  }
}

$(function () {
  popupOpen();
  document.addEventListener('swup:contentReplaced', popupOpen);
  popupClose();
  document.addEventListener('swup:contentReplaced', popupClose);
});
