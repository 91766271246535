import Swup from 'swup';
import $ from 'jquery';
import ScrollMagic from 'scrollmagic';

//주소에 따른 작업(각 페이지별 html에 클래스값 들어가기위한 스크립트, 메인에서 풀페이지 스크립트 불러오고 다른페이지에선 초기화 되도록 작업)
function projectContentReplaced() {
  if (document.querySelector('html') != null) {
    if (window.location.pathname === '/project.html' || window.location.pathname === '/project') {
      $('html').addClass('project');
      $('html').removeClass('sub');
      $('html').removeClass('home');
      $('html').removeClass('company');
      $('html').removeClass('careers');
      $('html').removeClass('contact');
      $('html').removeClass('tech');
      $('html').removeClass('detail');
      $('html').removeClass('career');
      $('html').removeClass('people');
      $('html').removeClass('blog');
      $('html').removeClass('blog-single');
      $('html').removeClass('intro-ing');
      $('html').removeClass('detail4');
    }
  }
}

//프로젝트 키워드 셀렉(프로젝트 키워드 클릭시 current 변경용)
function keywordAction() {
  if (document.querySelector('.project .box-keyword a') != null) {
    $('.project .box-keyword a').click(function () {
      $(this).parent().parent().addClass('is-current').siblings('li').removeClass('is-current');
    });
  }
}

//프로젝트 포스트 애니메이션(프로젝트 포스트 스크롤시 노출 애니메이션용)
function postAnimate() {
  if (document.querySelector('.box-post-item') != null) {
    var controller = new ScrollMagic.Controller();

    $('.box-post-item').each(function () {
      var ourScene = new ScrollMagic.Scene({
        triggerElement: this.children[0],
        triggerHook: 1,
      })
        .setClassToggle(this, 'is-active')
        .addTo(controller);
    });
  }
}

//프로젝트 필터(프로젝트 키워드클릭시 포스트 변경용)
function keywordFilter() {
  if (document.querySelector('html') != null) {
    $(document).ready(function () {
      $('.project .box-keyword .btn-keyword').click(function () {
        // fetch the class of the clicked item
        var ourClass = $(this).attr('data-filter');

        if (ourClass == 'all') {
          // show all our items
          $('.post-inner').children('.box-post').show();
        } else {
          // hide all elements that don't share ourClass
          $('.post-inner')
            .children('.box-post:not(.' + ourClass + ')')
            .hide();
          // show all elements that do share ourClass
          $('.post-inner')
            .children('.box-post.' + ourClass)
            .show();

          $('.box-post').each(function () {
            if ($(this).css('display') == 'block') {
              var dataSrc = $(this).find('.lazy').attr('data-src');
              $(this).find('.lazy').attr('srcset', dataSrc);
            }
          });
        }
        $('.post-inner').masonry();
      });
    });
  }
}

//프로젝트 호버(프로젝트 포스트 호버 모바일에서 이미지 클릭시 넘어가는거 방지용)
function hoverReturn() {
  if (document.querySelector('.box-post-item .thumb') != null) {
    var width_size = window.outerWidth;

    if (width_size >= 420) {
      $('.box-post-item .thumb').on('click', function () {
        return false;
      });
    }
  }
}

$(function () {
  projectContentReplaced();
  document.addEventListener('swup:contentReplaced', projectContentReplaced);
  keywordAction();
  document.addEventListener('swup:contentReplaced', keywordAction);
  postAnimate();
  document.addEventListener('swup:contentReplaced', postAnimate);
  keywordFilter();
  document.addEventListener('swup:contentReplaced', keywordFilter);
  hoverReturn();
  document.addEventListener('swup:contentReplaced', hoverReturn);
});
