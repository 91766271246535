import Swup from 'swup';
import $ from 'jquery';
import ScrollMagic from 'scrollmagic';
import gsap from 'gsap';

//주소에 따른 작업(각 페이지별 html에 클래스값 들어가기위한 스크립트, 메인에서 풀페이지 스크립트 불러오고 다른페이지에선 초기화 되도록 작업)
function techContentReplaced() {
  if (document.querySelector('html') != null) {
    if (window.location.pathname === '/tech.html' || window.location.pathname === '/tech') {
      console.log('includes(tech.html)');
      $('html').addClass('tech');
      $('html').removeClass('company');
      $('html').removeClass('sub');
      $('html').removeClass('home');
      $('html').removeClass('project');
      $('html').removeClass('careers');
      $('html').removeClass('contact');
      $('html').removeClass('detail');
      $('html').removeClass('career');
      $('html').removeClass('people');
      $('html').removeClass('blog');
      $('html').removeClass('blog-single');
      $('html').removeClass('intro-ing');
      $('html').removeClass('detail4');
    }
  }
}

/* Tech - video 팝업 제어 */
function techPlay() {
  if (document.querySelector('.tech') != null) {
    $(document).ready(function () {
      const popup = $('.video-popup');
      const videoContainer = $('.video-popup .video-inner');

      /* 비디오 팝업 열기 */
      $('.tech-play-btn').click(function (e) {
        // video source (data-source)
        const source = e.target.dataset.source;
        const title = e.target.dataset.title;

        if (source && title) {
          // video 재생이 가능할 때까지 로딩스피너 활성화
          $('.loading-wrapper').addClass('active');

          // video html 삽입
          const videoHtml = `
            <picture class="bg-picture">
              <video loop="" playsinline="" autoplay="autoplay" class="fullscreen-video video" title="${title}">
                <source src="${source}" type="video/mp4" />
              </video>
            </picture>`;
          videoContainer.html(videoHtml);

          // video 재생이 가능할 때 팝업 오픈, 로딩스피너 비활성화
          $('.video-popup video').on('canplay', function () {
            $('.loading-wrapper').removeClass('active');
            popup.addClass('is-open');
          });
        }
      });

      /* 비디오 팝업 닫기 */
      $('.video-popup-close').click(function (e) {
        popup.removeClass('is-open');
        $('.video-inner .bg-picture').remove();
      });
    });
  }
}

$(function () {
  techContentReplaced();
  document.addEventListener('swup:contentReplaced', techContentReplaced);

  techPlay();
  document.addEventListener('swup:contentReplaced', techPlay);
});
