import Swup from 'swup';
import $ from 'jquery';
import ScrollMagic from 'scrollmagic';
import { SERVER_URL } from '../contants';

//주소에 따른 작업(각 페이지별 html에 클래스값 들어가기위한 스크립트, 메인에서 풀페이지 스크립트 불러오고 다른페이지에선 초기화 되도록 작업)
function peopleContentReplaced() {
  if (document.querySelector('html') != null) {
    if (window.location.pathname === '/people.html' || window.location.pathname === '/people') {
      $('html').addClass('people');
      $('html').removeClass('sub');
      $('html').removeClass('home');
      $('html').removeClass('project');
      $('html').removeClass('careers');
      $('html').removeClass('company');
      $('html').removeClass('tech');
      $('html').removeClass('detail');
      $('html').removeClass('career');
      $('html').removeClass('contact');
      $('html').removeClass('blog');
      $('html').removeClass('blog-single');
      $('html').removeClass('intro-ing');
      $('html').removeClass('detail4');
    }
  }
}

//사람들 트리거
function peopleActive() {
  if (document.querySelector('html') != null) {
    $('#peopleSlide .swiper-slide').each(function (i) {
      var controller = new ScrollMagic.Controller({ vertical: false });

      var ourScene = new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: 0.1,
        duration: '100%',
      })
        .setClassToggle(this, 'is-active')
        .addTo(controller);
    });
  }
}

//사람들 텍스트
function peopleText() {
  if (document.querySelector('html') != null) {
    var controller = new ScrollMagic.Controller({ vertical: false });
    var image = $('#peopleSlide .swiper-slide');
    var text = $('.title-people-wrapper li');

    for (var i = 0; i < image.length; i++) {
      var ourScene = new ScrollMagic.Scene({
        triggerElement: image[i],
        triggerHook: 0.65,
        duration: '84%',
      })
        .setClassToggle(text[i], 'is-active')
        .addTo(controller);
    }
  }
}

//사람들 카운트
function peopleCount() {
  if (document.querySelector('html') != null) {
    var controller = new ScrollMagic.Controller({ vertical: false });
    var image = $('#peopleSlide .swiper-slide');
    var slides = $('.people-pagination-fraction .pagination-current');
    for (var i = 0; i < image.length; i++) {
      var ourScene = new ScrollMagic.Scene({
        triggerElement: image[i],
        triggerHook: 1,
        duration: '85%',
      })
        .setClassToggle(slides[i], 'is-active')
        .addTo(controller);
    }
  }
}

//사람들 애니
function peopleAni() {
  if (document.querySelector('html') != null) {
    var div = $('.horizontal-scroll-wrapper');
    setInterval(function () {
      var pos = div.scrollTop();
      div.scrollTop(pos + 2);
    }, 15);
  }

  $('.horizontal-scroll-wrapper').scroll(function () {
    var scrollTop = $(this).scrollTop();
    var innerHeight = $(this).innerHeight();
    var scrollHeight = $(this).prop('scrollHeight');

    if (scrollTop + innerHeight >= scrollHeight) {
      $(this).scrollTop(0);
    }
  });
}

//사람들 랜덤
function peopleRandom() {
  if (document.querySelector('html') != null) {
    var list = document.querySelectorAll('#peopleSlideSwiper li');
    var ul = document.querySelector('#peopleSlide ul');
    var len = list.length;
    var textUl = document.querySelector('.title-people-wrapper ul');
    var textList = document.querySelectorAll('.title-people-wrapper ul li');
    var arr = [];

    if (ul != undefined) {
      for (var i = len - 1; i > 0; i--) {
        var randomNum = Math.floor(Math.random() * i) || 0;
        ul.appendChild(list[randomNum]);
        textUl.appendChild(textList[randomNum]);
        $('#peopleSlideSwiper .swiper-slide').first().addClass('fist');
      }
    }
  }
}

//사람들 리스트
function peopleList() {
  if (document.getElementById('peopleSlide')) {
    const url = SERVER_URL + '/api/organization/members/profiles';

    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      async: false,
      timeout: 5000,
      success: function (response) {
        const count = response.body.length;
        let list = '';
        let titleList = '';

        for (var i = 0; i < count; i++) {
          var imgUrl = response.body[i].generalPictureUrl;
          var mobileImgUrl = response.body[i].mobilePictureUrl;
          var words = response.body[i].motto;
          var name = response.body[i].name;
          var team = response.body[i].departmentName;

          list +=
            '<li class="swiper-slide"><picture class="bg-picture"> <source class="lazy" data-src="' +
            imgUrl +
            '" media="(min-width: 768px)" type="image/webp"/><source class="lazy" data-src="' +
            mobileImgUrl +
            '" media="(max-width: 767px)"/><img class="bg-img lazy" srcset="' +
            imgUrl +
            '" alt="' +
            name +
            '" /></picture><div class="box-people-info"><h2 class="title"><span class="text">' +
            words +
            '</span></h2><div class="name">' +
            name +
            '</div><div class="position">#' +
            team +
            '</div></div></li>';

          titleList +=
            '<li><div class="title-people-inner"><h2 class="title"><span class="text">' +
            words +
            '</span></h2><div class="name">' +
            name +
            '</div><div class="position">#' +
            team +
            '</div></div></li>';
        }

        $('#peopleSlideSwiper').html(list);
        $('#peopleTitleSlide').html(titleList);
        setPagination(count);
        peopleRandom();
      },
    });
  }
}

//사람들 페이지네이션
function setPagination(count) {
  var list = '';
  for (var i = 1; i <= count; i++) {
    if (i < 10) {
      i = '0' + i;
    }
    list += '<span class="pagination-current">' + i + '</span>';
  }

  list += '<span class="pagination-total">' + count + '</span>';
  $('#peopleSlidePagination').html(list);
}

$(function () {
  peopleContentReplaced();
  document.addEventListener('swup:contentReplaced', peopleContentReplaced);
  peopleList();
  document.addEventListener('swup:contentReplaced', peopleList);
  peopleActive();
  document.addEventListener('swup:contentReplaced', peopleActive);
  peopleText();
  document.addEventListener('swup:contentReplaced', peopleText);
  peopleCount();
  document.addEventListener('swup:contentReplaced', peopleCount);
  peopleAni();
  document.addEventListener('swup:contentReplaced', peopleAni);
});
