import $ from 'jquery';
import gsap from 'gsap';

//네비 클릭액션(네비 열고 닫기)
function gnbLink() {
  if (document.querySelector('.gnb-item a') != null) {
    $('.gnb-item a').off('click');
    $('.gnb-item a').on('click', function () {
      $('body').removeClass('is-gnb-on');
      $('html').removeClass('is-hidden');
    });
  }
}

//네비 로고 클릭액션(네비연상태에서 로고 클릭시 닫히도록 작업)
function logoClick() {
  if (document.querySelector('.link-logo') != null) {
    $('.link-logo').on('click', function () {
      $('body').removeClass('is-gnb-on');
      $('html').removeClass('is-hidden');
    });
  }
}

//메뉴 토글(메뉴열고 닫기 버튼 액션)
function gnbToggle() {
  if (document.querySelector('.btn-gnb') != null) {
    $('.btn-gnb').off('click');
    $('.btn-gnb').on('click', function () {
      if ($('body').hasClass('is-gnb-on')) {
        $('body').removeClass('is-gnb-on');
        $('body').addClass('is-gnb-out');
        $('html').removeClass('is-hidden');
      } else {
        $('body').removeClass('is-gnb-out');
        $('body').addClass('is-gnb-on');
        $('html').addClass('is-hidden');
      }
      $('.btn_gnb .sr-only').text(function (i, text) {
        return text === '메뉴보기' ? '닫기' : '메뉴보기';
      });
    });
  }
}

//메뉴 픽스트 로고 변환(로고 픽시드시 로고변환용)
function gnbLogoAnime() {
  if (document.querySelector('html') != null) {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if (scroll >= 1) {
        $('body').addClass('fixed');
      } else {
        $('body').removeClass('fixed');
      }
    });
  }
}

//네비 액션(네비 에서 카테고리 넘어길시 페이지 넘김 애니메이션)
function navGnbAction() {
  if (document.querySelector('body') != null) {
    $('.gnb-item').click(function () {
      var tl = gsap.timeline();
      tl.to('.box-loding-right', 0, { autoAlpha: 0 });
      tl.to('.box-loding-bottom', 0.4, { y: '-100%' }, '+=0.9');
      tl.to('.box-loding-bottom', 0.4, { y: '-200%' }, '+=0.2');
      tl.to('.box-loding-bottom', 0, { y: '0%', autoAlpha: 0 });
      tl.to('.box-loding-bottom', 0, { autoAlpha: 1 });
      tl.to('.box-loding-right', 0, { autoAlpha: 1 });
    });
  }
}

//네비 액티브(현재 페이지 네비게이션 current 표시용)
function gnbActive() {
  if (document.querySelector('.gnb-item a') != null) {
    var path = window.location.href;
    $('.gnb-item a').each(function () {
      if (this.href === path) {
        $(this).parent('li').addClass('is-current').siblings('li').removeClass('is-current');
      }
    });
  }
}

//블로그 클릭시 화면전환 애니메이션
function blogGnbAction() {
  if (document.querySelector('body') != null) {
    $('.gnb-footer .btn-sns-item').click(function () {
      $('body').removeClass('is-gnb-on');
      $('html').removeClass('is-hidden');

      var tl = gsap.timeline();
      tl.to('.box-loding-right', 0, { autoAlpha: 0 });
      tl.to('.box-loding-bottom', 0.4, { y: '-100%' }, '+=0.9');
      tl.to('.box-loding-bottom', 0.4, { y: '-200%' }, '+=0.2');
      tl.to('.box-loding-bottom', 0, { y: '0%', autoAlpha: 0 });
      tl.to('.box-loding-bottom', 0, { autoAlpha: 1 });
      tl.to('.box-loding-right', 0, { autoAlpha: 1 });
    });
  }
}

$(function () {
  gnbLink();
  document.addEventListener('swup:contentReplaced', gnbLink);

  logoClick();
  document.addEventListener('swup:contentReplaced', logoClick);

  gnbToggle();
  document.addEventListener('swup:contentReplaced', gnbToggle);

  gnbLogoAnime();
  document.addEventListener('swup:contentReplaced', gnbLogoAnime);

  navGnbAction();
  document.addEventListener('swup:clickLink', navGnbAction);

  gnbActive();
  document.addEventListener('swup:contentReplaced', gnbActive);

  blogGnbAction();
  document.addEventListener('swup:clickLink', blogGnbAction);
});
