import $ from 'jquery';

//쿠키랜딩페이지 액션(인트로 화면 쿠키:브라우저 끄거나 새로고침시 다시 노출)
function cookieTime() {
  if (document.querySelector('html') != null) {
    setCookieMobile('todayCookie', 'done', 1);
    $('html').addClass('is-cookie');
  }
}

function setCookieMobile(name, value, expiredays) {
  var todayDate = new Date();
  todayDate.setDate(todayDate.getDate() + expiredays);
  document.cookie = name + '=' + escape(value) + '; path=/; expires=' + todayDate.toGMTString() + ';';
}
function getCookieMobile() {
  var cookiedata = document.cookie;
  if (cookiedata.indexOf('todayCookier=done') < 0) {
    $('html').removeClass('is-cookie');
    $('html')
      .addClass('intro-ing')
      .delay(9500)
      .queue(function (next) {
        $(this).removeClass('intro-ing').addClass('intro-end');
        next();
      });
  } else {
    $('html').addClass('is-cookie');
  }
}
getCookieMobile();

$(function () {
  cookieTime();
  document.addEventListener('swup:contentReplaced', cookieTime);

  getCookieMobile();
});
