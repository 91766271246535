import $ from 'jquery';

export function checkInputValidation(inputId, message) {
  const input = $(inputId);

  const val = input.val();

  if (!val) {
    alert(message);
    input.focus();
    return false;
  }

  return true;
}

export function checkValidationEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function checkValidationNumber(number) {
  const re = /^[0-9-]*$/;
  return re.test(String(number));
}
