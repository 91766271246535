import Swup from 'swup';
import $ from 'jquery';
import ScrollMagic from 'scrollmagic';
import { TimelineMax, TweenMax, Linear } from 'gsap';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';
import 'fullpage.js/dist/jquery.fullpage';

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

//주소에 따른 작업(각 페이지별 html에 클래스값 들어가기위한 스크립트, 메인에서 풀페이지 스크립트 불러오고 다른페이지에선 초기화 되도록 작업)
function indexContentReplaced() {
  if (document.querySelector('html') != null) {
    if (window.location.pathname === '/index.html' || window.location.pathname === '/') {
      console.log('includes(index.html)');
      $('html').addClass('home');
      $('html').removeClass('sub');
      $('html').removeClass('project');
      $('html').removeClass('company');
      $('html').removeClass('careers');
      $('html').removeClass('contact');
      $('html').removeClass('tech');
      $('html').removeClass('detail');
      $('html').removeClass('career');
      $('html').removeClass('people');
      $('html').removeClass('blog');
      $('html').removeClass('blog-single');
      $('html').removeClass('detail4');

      $('.gnb-category .gnb-item').removeClass('is-current');

      $('a').on('click', function () {
        if ($(this).hasClass('link-logo') === true) {
        } else {
          setTimeout(function () {
            $.fn.fullpage.destroy('all');
          }, 1000);
        }
      });
    }
  }
}

//메인 풀페이지(풀페이지 플러그인 불러오기)
function fullPage() {
  if (document.querySelector('#fullpage') != null) {
    try {
      $.fn.fullpage.destroy('all');
    } catch (e) {}

    $('#fullpage').fullpage({
      scrollingSpeed: 1000,
    });

    // document.addEventListener('swup:willReplaceContent', () => {
    //   console.log('fullpage.destroy');
    //   setTimeout(function () {
    //     $.fn.fullpage.destroy('all');
    //     document.removeEventListener('swup:willReplaceContent');
    //   }, 1000);
    // });

    // Swup.on('contentReplaced', () => {
    //   setTimeout(function () {
    //     $.fn.fullpage.destroy('all');
    //   }, 1000);
    //   Swup.off()
    // });
  }
}

//메인 포트폴리오 랜덤노출
function mainRandom() {
  if (document.querySelector('html') != null) {
    $(document).ready(function () {
      $('.fullpage-wrapper').each(function () {
        var $ul = $(this);
        var $liArr = $ul.children('.main-section.portfolio');
        $liArr
          .sort(function (a, b) {
            var temp = parseInt(Math.random() * 5);
            var isOddOrEven = temp % 2;
            var isPosOrNeg = temp > 5 ? 1 : -1;
            return isOddOrEven * isPosOrNeg;
          })
          .appendTo($ul);
      });
    });
  }
}

//메인 카운팅(메인 레이아웃당 넘버링)
function mainPaing() {
  // if(document.querySelector('html') != null) {
  if (document.querySelector('.count') != null) {
    var controller = new ScrollMagic.Controller({ globalSceneOptions: { duration: '100%', triggerHook: 0.2 } });

    new ScrollMagic.Scene({ triggerElement: '.pofolio-num1' }).setClassToggle('.sec_1', 'is-active').addTo(controller);
    new ScrollMagic.Scene({ triggerElement: '.pofolio-num2' }).setClassToggle('.sec_2', 'is-active').addTo(controller);
    new ScrollMagic.Scene({ triggerElement: '.pofolio-num3' }).setClassToggle('.sec_3', 'is-active').addTo(controller);
    new ScrollMagic.Scene({ triggerElement: '.pofolio-num4' }).setClassToggle('.sec_4', 'is-active').addTo(controller);
    new ScrollMagic.Scene({ triggerElement: '.pofolio-num5' }).setClassToggle('.sec_5', 'is-active').addTo(controller);
    new ScrollMagic.Scene({ triggerElement: '.pofolio-num6' }).setClassToggle('.sec_6', 'is-active').addTo(controller);
    new ScrollMagic.Scene({ triggerElement: '.pofolio-num7' }).setClassToggle('.sec_7', 'is-active').addTo(controller);
    new ScrollMagic.Scene({ triggerElement: '.pofolio-num8' }).setClassToggle('.sec_8', 'is-active').addTo(controller);
  }
}

//페이징 fixed(메인 스크롤시 카운팅 버튼 노출되도록 작업)
function pagingFixed() {
  if (document.querySelector('.pofolio-num2') != null) {
    var controller = new ScrollMagic.Controller();

    var ourScene = new ScrollMagic.Scene({
      triggerElement: '.pofolio-num2',
      triggerHook: 1,
    })
      .setClassToggle('.section_counting', 'is-fixed')
      .addTo(controller);
  }
}

//텍스트 애니메이션(메인 스크롤시 텍스트 노출용)
function textAnimate() {
  if (document.querySelector('.portfolio') != null) {
    var controller = new ScrollMagic.Controller();

    $('.portfolio').each(function () {
      var ourScene = new ScrollMagic.Scene({
        triggerElement: this.children[0],
        triggerHook: 0.4,
      })
        .setClassToggle(this, 'is-active')
        .addTo(controller);
    });
  }
}

//텍스트 스크롤링(fst)(메인 텍스트 스크롤링용)
function textScrollingFst() {
  if (document.querySelector('.rolling-text-fst') != null) {
    // console.clear();
    var $text = $('.rolling-text-fst');
    var $item = $('.text-inner-fst');

    var timeline = new TimelineMax();
    var items = document.querySelectorAll('.rolling-text-fst');
    var startDuration = 98;

    for (var i = 0; i < items.length; i++) {
      var myDelay = i * 0.5;
      animate(items[i], myDelay);
    }

    function animate(item) {
      item.anim = TweenMax.to(item, startDuration, { x: -$item.width(), ease: Linear.easeNone, repeat: -1 });
    }

    $('.rolling-text').hover(
      function () {
        TweenMax.to(this.anim, 2, { timeScale: 7 });
      },
      function () {
        TweenMax.to(this.anim, 2, { timeScale: 1 });
      },
    );
  }
}

//텍스트 스크롤링(mid)(메인 텍스트 스크롤링용)
function textScrollingMid() {
  if (document.querySelector('.rolling-text-mid') != null) {
    // console.clear();
    var $text = $('.rolling-text-mid');
    var $item = $('.text-inner-mid');

    var timeline = new TimelineMax();
    var items = document.querySelectorAll('.rolling-text-mid');
    var startDuration = 220;

    for (var i = 0; i < items.length; i++) {
      var myDelay = i * 0.5;
      animate(items[i], myDelay);
    }

    function animate(item) {
      item.anim = TweenMax.to(item, startDuration, { x: -$item.width(), ease: Linear.easeNone, repeat: -1 });
    }

    $('.rolling-text').hover(
      function () {
        TweenMax.to(this.anim, 2, { timeScale: 7 });
      },
      function () {
        TweenMax.to(this.anim, 2, { timeScale: 1 });
      },
    );
  }
}

//텍스트 스크롤링(lst)(메인 텍스트 스크롤링용)
function textScrollingLst() {
  if (document.querySelector('.rolling-text-lst') != null) {
    // console.clear();
    var $text = $('.rolling-text-lst');
    var $item = $('.text-inner-lst');

    var timeline = new TimelineMax();
    var items = document.querySelectorAll('.rolling-text-lst');
    var startDuration = 70;

    for (var i = 0; i < items.length; i++) {
      var myDelay = i * 0.5;
      animate(items[i], myDelay);
    }

    function animate(item) {
      item.anim = TweenMax.to(item, startDuration, { x: -$item.width(), ease: Linear.easeNone, repeat: -1 });
    }

    $('.rolling-text').hover(
      function () {
        TweenMax.to(this.anim, 2, { timeScale: 7 });
      },
      function () {
        TweenMax.to(this.anim, 2, { timeScale: 1 });
      },
    );
  }
}

//텍스트 롤링 호버 액션	(메인 텍스트 호버시 배경 바꾸는용)
function textScrollingHover() {
  if (document.querySelector('.text-item') != null) {
    $('.text-inner-fst').hover(
      function () {
        $('body').addClass('text-fst');
      },
      function () {
        $('body').removeClass('text-fst');
      },
    );
    $('.text-inner-mid').hover(
      function () {
        $('body').addClass('is-mid');
      },
      function () {
        $('body').removeClass('is-mid');
      },
    );
    $('.text-inner-lst').hover(
      function () {
        $('body').addClass('text-lst');
      },
      function () {
        $('body').removeClass('text-lst');
      },
    );
  }
}

$(function () {
  indexContentReplaced();
  document.addEventListener('swup:contentReplaced', indexContentReplaced);
  fullPage();
  document.addEventListener('swup:contentReplaced', fullPage);
  mainPaing();
  document.addEventListener('swup:contentReplaced', mainPaing);
  // mainRandom();
  // document.addEventListener('swup:contentReplaced', mainRandom);
  pagingFixed();
  document.addEventListener('swup:contentReplaced', pagingFixed);
  textAnimate();
  document.addEventListener('swup:contentReplaced', textAnimate);

  textScrollingFst();
  document.addEventListener('swup:contentReplaced', textScrollingFst);
  textScrollingMid();
  document.addEventListener('swup:contentReplaced', textScrollingMid);
  textScrollingLst();
  document.addEventListener('swup:contentReplaced', textScrollingLst);
  textScrollingHover();
  document.addEventListener('swup:contentReplaced', textScrollingHover);
});
