import $ from 'jquery';

//네비 높이값 작업
function navHieght() {
  if (document.querySelector('html') != null) {
    var onResize = function () {
      var docHeight = $(window).outerHeight();
      $('.clip-container').css({ height: docHeight });
    };
    $(window).on('resize', onResize);
    onResize();
  }
}

//상단 배너 풀페이지
function detailHieght() {
  if (document.querySelector('html') != null && !document.querySelector('body').classList.contains('renewal-html') ) {
    var onResize = function () {
      var docHeight = $(window).outerHeight();
      $('.detail-header').css({ height: docHeight });
    };
    $(window).on('resize', onResize);
    onResize();
  }
}

$(function () {
  navHieght();
  document.addEventListener('swup:contentReplaced', navHieght);
  detailHieght();
  document.addEventListener('swup:contentReplaced', detailHieght);
});
