import $ from 'jquery';
//디테일컷 헤더색변경(디테일 배경 흰색배경일시 로고 검정으로 변경되는용)
function detailLogoColor() {
  if (document.querySelector('#colorBox') != null) {
    $(document).on('scroll resize ready', function () {
      if ($('.page-section').hasClass('detail') === true) {
        var divOffset = $('#colorBox').offset();
        var width_size = window.outerWidth;
        if (width_size >= 420) {
          if ($(document).scrollTop() > divOffset.top - 158) {
            $('body').addClass('color-black');
          } else {
            $('body').removeClass('color-black');
          }
        } else {
          if ($(document).scrollTop() > divOffset.top - 65) {
            $('body').addClass('color-black');
          } else {
            $('body').removeClass('color-black');
          }
        }
      }
    });
  }
}
function detailLogoColorWhite() {
  if (document.querySelector('#colorBoxWhite') != null) {
    $(document).on('scroll resize ready', function () {
      if ($('.page-section').hasClass('detail') === true) {
        var divOffset = $('#colorBoxWhite').offset();
        var width_size = window.outerWidth;

        if (width_size >= 420) {
          if ($(document).scrollTop() > divOffset.top - 158) {
            $('body').addClass('color-white');
          } else {
            $('body').removeClass('color-white');
          }
        } else {
          if ($(document).scrollTop() > divOffset.top - 65) {
            $('body').addClass('color-white');
          } else {
            $('body').removeClass('color-white');
          }
        }
      }
    });
  }
}

// detail logo color change
function detailLogo() {
  if (document.querySelector('.box-color-logo') != null) {
    var idx = 0;
    $(document).on('scroll resize ready', function () {
      if ($('.page-section').hasClass('detail') === true ) {
        var body = $('body');
        var target = $('.box-color-logo');
        var current = target[idx];
        var prev = target[idx - 1];

        if(idx % 2 === 0) {
          if( current && current.getBoundingClientRect().top - 158 <= 0) {
            body.addClass('color-black');
            idx ++;
          }
          if( prev && prev.getBoundingClientRect().top - 158 > 0) {
            body.addClass('color-black');
            idx--;
          }
        } else {
          if( current && current.getBoundingClientRect().top - 158 <= 0) {
            body.removeClass('color-black');
            idx ++;
          }
          if( prev && prev.getBoundingClientRect().top - 158 > 0) {
            body.removeClass('color-black');
            idx--;
          }
        }
      }
    })
  }
}

//블로그 헤더색변경(블로그 상세페이지 배경 흰색배경일시 로고 검정으로 변경되는용)
function blogLogoColor() {
  if (document.querySelector('#colorBox') != null) {
    $(document).on('scroll resize ready', function () {
      if ($('.page-section').hasClass('blog-single') === true) {
        var divOffset = $('#colorBox').offset();
        var width_size = window.outerWidth;

        if (width_size >= 420) {
          if ($(document).scrollTop() > divOffset.top) {
            $('body').addClass('color-black');
          } else {
            $('body').removeClass('color-black');
          }
        } else {
          if ($(document).scrollTop() > divOffset.top - 158) {
            $('body').addClass('color-black');
          } else {
            $('body').removeClass('color-black');
          }
        }
      }
    });
  }
}
$(function () {
  // detailLogoColor();
  // document.addEventListener('swup:contentReplaced', detailLogoColor);

  // detailLogoColorWhite();
  // document.addEventListener('swup:contentReplaced', detailLogoColorWhite);
  
  // detailLogoClass29();
  // document.addEventListener('swup:contentReplaced', detailLogoClass29);

  detailLogo();
  document.addEventListener('swup:contentReplaced', detailLogo);

  blogLogoColor();
  document.addEventListener('swup:contentReplaced', blogLogoColor);
});
