import Swup from 'swup';
import $ from 'jquery';

//주소에 따른 작업(각 페이지별 html에 클래스값 들어가기위한 스크립트, 메인에서 풀페이지 스크립트 불러오고 다른페이지에선 초기화 되도록 작업)
function careersdetailContentReplaced() {
  if (document.querySelector('html') != null) {
    if (window.location.href.indexOf('/careers/') > -1) {
      console.log('indexOf(careers)');
      $('html').addClass('career');
      $('html').removeClass('sub');
      $('html').removeClass('home');
      $('html').removeClass('project');
      $('html').removeClass('careers');
      $('html').removeClass('company');
      $('html').removeClass('tech');
      $('html').removeClass('detail');
      $('html').removeClass('people');
      $('html').removeClass('blog');
      $('html').removeClass('blog-single');
      $('html').removeClass('intro-ing');
      $('html').removeClass('detail4');
    }
  }
}

//첨부파일 파일명넣기
function uploadFile() {
  if (document.querySelector('body') != null) {
    $('input[name=resume]').on('change', function () {
      var file = this.files[0],
        fileName = file.name;

      $('.resume .text-file').addClass('is-active');
      $('.file-text-item.resume').text(fileName);

      //CustomFileHandlingFunction(file);
    });
  }
}

//셀렉박스 컬러색 변경
function selectColor() {
  if (document.querySelector('body') != null) {
    $('.form-arr-select').change(function () {
      $('.form-arr-select').addClass('is-active');
    });
  }
}

//첨부파일 파일명넣기
function uploadFilePor() {
  if (document.querySelector('body') != null) {
    $('input[name=portfolio]').on('change', function () {
      var file = this.files[0],
        fileName = file.name;

      $('.portfolio .text-file').addClass('is-active');
      $('.file-text-item.portfolio').text(fileName);

      //CustomFileHandlingFunction(file);
    });
  }
}

$(function () {
  careersdetailContentReplaced();
  document.addEventListener('swup:contentReplaced', careersdetailContentReplaced);
  uploadFile();
  document.addEventListener('swup:contentReplaced', uploadFile);
  selectColor();
  document.addEventListener('swup:contentReplaced', selectColor);
  uploadFilePor();
  document.addEventListener('swup:contentReplaced', uploadFilePor);
});
