import $ from 'jquery';

//인트로 스킵(인트로 스킵 버튼)
function introSkip() {
  if (document.querySelector('html') != null) {
    $('.btn-skip').click(function () {
      $('html').removeClass('intro-ing').addClass('intro-end');
      $('video').trigger('pause');
    });
  }
}

$(function () {
  introSkip();
  document.addEventListener('swup:contentReplaced', introSkip);
});
