import $ from 'jquery';

//페이지 전환시 스크롤 탑(페이지전환시 전페이지 스크롤유지되어 스크롤 탑 및 전 클래스 들어간거 초기화)
function scrollTop() {
  if (document.querySelector('html') != null) {
    $('body').removeClass('text-fst');
    $('body').removeClass('color-black');
    $(window).scrollTop(0);
  }
}

//스크롤탑(스크롤탑 버튼 클릭시 상단으로 이동용)
function scrollTopBtn() {
  if (document.querySelector('.detail-footer') != null) {
    $('.btn-top').click(function () {
      $('html, body').animate(
        {
          scrollTop: 0,
        },
        600,
      );
    });
  }
}

$(function () {
  scrollTop();
  document.addEventListener('swup:contentReplaced', scrollTop);

  scrollTopBtn();
  document.addEventListener('swup:contentReplaced', scrollTopBtn);
});
