import $ from 'jquery';
import ScrollMagic from 'scrollmagic';
import gsap from 'gsap';
import { TweenMax } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Swiper from 'swiper';

gsap.registerPlugin(ScrollTrigger);

//디테일 애니메이션(디테일 페이지 스크롤시 노출되는 애니메이션용)
function detailAni() {
  if (document.querySelector('.anime') != null) {
    var controller = new ScrollMagic.Controller();

    $('.anime').each(function () {
      var ourScene = new ScrollMagic.Scene({
        triggerElement: this.children[0],
        triggerHook: 0.9,
        reverse: false,
      })
        .setClassToggle(this, 'is-active')
        .addTo(controller);
    });
  }

  if (document.querySelector('.anime-up') != null) {
    var controller = new ScrollMagic.Controller();

    $('.anime-up').each(function () {
      var ourScene = new ScrollMagic.Scene({
        triggerElement: this.children[0],
        triggerHook: 0.8,
        reverse: false,
      })
        .setClassToggle(this, 'is-active')
        .addTo(controller);
    });
  }

  if (document.querySelector('.anime-down') != null) {
    var controller = new ScrollMagic.Controller();

    $('.anime-down').each(function () {
      var ourScene = new ScrollMagic.Scene({
        triggerElement: this.children[0],
        triggerHook: 0.7,
        reverse: false,
      })
        .setClassToggle(this, 'is-active')
        .addTo(controller);
    });
  }

  // 순차적으로 스르륵 올라오는 애니메이션
  if (document.querySelector('.anime-sequp') != null) {
    var controller = new ScrollMagic.Controller();
    // target, duration, from, to, stargger
    var tweenStagger = TweenMax.staggerFromTo(
      '.anime-sequp',
      0.05,
      { y: '5vh', opacity: 0 },
      { y: 0, opacity: 1 },
      0.2,
    );

    var scene = new ScrollMagic.Scene({
      triggerElement: '#trigger',
      offset: -150,
    })
      .setTween(tweenStagger)
      .addTo(controller)
      .reverse(0);
  }

  // 순차적으로 등장하는 애니메이션 (제자리)
  if (document.querySelector('.anime-seq') != null) {
    var controller = new ScrollMagic.Controller();
    // target, duration, from, to, stargger
    var tweenStagger = TweenMax.staggerFromTo('.anime-seq', 0.05, { opacity: 0 }, { opacity: 1 }, 0.5);

    var scene = new ScrollMagic.Scene({
      triggerElement: '#trigger',
      offset: -150,
    })
      .setTween(tweenStagger)
      .addTo(controller)
      .reverse(0);
  }

  // opacity 애니메이션
  if (document.querySelector('.anime-op')) {
    var controller = new ScrollMagic.Controller();

    $('.anime-op').each(function () {
      var Scene = new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: 0.9,
        reverse: false,
      })
        .setClassToggle(this, 'op-active')
        .addTo(controller);
    });
  }

  // detail 30
  // 좌측에서 우측으로 슬라이드
  if (document.querySelector('.detail30')) {
    if (document.querySelector('.anime-right') != null) {
      if (window.innerWidth >= 419) {
        var controller = new ScrollMagic.Controller();
        var tweenStagger = TweenMax.staggerFromTo(
          '.anime-right',
          0.4,
          { x: -100, opacity: 0 },
          { x: 0, opacity: 1 },
          0.5,
        );

        var scene = new ScrollMagic.Scene({
          triggerElement: '#trigger',
          offset: -150,
        })
          .setTween(tweenStagger)
          .addTo(controller)
          .reverse(0);
      }
    }
  }

  // detail 33
  if (document.querySelector('.detail33')) {
    if (document.querySelector('.anime-pannel') != null) {
      var controller = new ScrollMagic.Controller();
      var ourScene = new ScrollMagic.Scene({
        triggerElement: '#trigger',
        triggerHook: 0.7,
        reverse: false,
      })
        .setClassToggle('.anime-pannel', 'active')
        .addTo(controller)
        .reverse(0);
    }
  }

  // detail36
  // 우측에서 좌측으로 슬라이드
  if (document.querySelector('.detail36')) {
    if (document.querySelector('.anime-left') != null) {
      var controller = new ScrollMagic.Controller();
      var tweenStagger = TweenMax.staggerFromTo('.anime-left', 0.8, { x: 100, opacity: 0 }, { x: 0, opacity: 1 }, 0.25);

      var scene = new ScrollMagic.Scene({
        triggerElement: '#left-trigger',
        offset: -150,
      })
        .setTween(tweenStagger)
        .addTo(controller)
        .reverse(0);
    }
  }

  // detail40
  // car 애니메이션
  if (document.querySelector('.detail40')) {
    if (document.querySelector('.car-ani')) {
      var controller = new ScrollMagic.Controller();

      $('.car-ani').each(function () {
        var Scene = new ScrollMagic.Scene({
          triggerElement: this,
          triggerHook: 0.9,
          reverse: false,
        })
          .setClassToggle(this, 'anime-car')
          .addTo(controller);
      });
    }
  }
}

function detailCardShow() {
  // detail 25 해당 스크롤에 도달하면 한번에 애니메이션 작동
  if (document.querySelector('.anime-flex') != null) {
    var trigger = document.querySelector('.detail25 .list-flex-anime');
    var triggerTop = trigger.getBoundingClientRect().top + window.pageYOffset;
    var mTriggerTop = trigger.getBoundingClientRect().top + window.pageYOffset - window.innerHeight;

    // console.log(mTriggerTop);
    document.addEventListener('scroll', () => {
      var scroll = window.scrollY;

      window.innerWidth <= 419
        ? scroll >= mTriggerTop && $('.anime-flex').addClass('is-active')
        : scroll >= triggerTop && $('.anime-flex').addClass('is-active');
    });
  }

  // detail26
  window.onload = function () {
    if (document.querySelector('.detail26 .layer') != null && window.innerWidth <= 419) {
      const tl = gsap.timeline({ paused: true });
      tl.set('.detail26 .layer', { opacity: 1 });
      tl.to('.detail26 .layer', { y: '-10vh', opacity: 0, duration: 0.5, ease: 'easeOut' }).from(
        '.detail26 .layer-ab',
        { y: '10vh', opacity: 0, duration: 0.5 },
      );

      ScrollTrigger.create({
        animation: tl,
        trigger: '.detail26 .layer',
        start: 'top center',
        toggleClass: { targets: '.layer', className: 'is-active' },
      });
      window.addEventListener('resize', () => {
        if (window.innerWidth > 375) {
          gsap.to('.detail26 .layer', { opacity: 1, y: 0, duration: 0.5 });
        }
        if (window.innerWidth <= 419) {
          gsap.to('.detail26 .layer', { opacity: 0, y: 0 });
        }
      });
    }
  };
}

function detail37() {
  ///////////////////////////
  // detail 37
  if (document.querySelector('.detail37')) {
    // sec-2 스크롤 값에 따라 움직이는 combine components 애니메이션
    let combine = document.querySelectorAll('.layer-wrap');

    combine.forEach((section) => {
      let imgani = gsap.to(section.querySelector('img'), {
        x: '0',
        ease: 'none',
      });

      let progressTo = gsap.quickTo(imgani, 'progress', {
        ease: 'power3',
        duration: parseFloat(section.dataset.scrub) || 0.1,
      });

      gsap.to(section.querySelector('.layer-side.left'), {
        x: '15vw',
        ease: 'none',
        scrollTrigger: {
          scrub: false,
          trigger: '.sec-2',
          start: 'top top',
          end: '+=800',
          onUpdate: (self) => progressTo(self.progress),
        },
      });

      gsap.to(section.querySelector('.layer-side.right'), {
        x: '-15vw',
        ease: 'none',
        scrollTrigger: {
          scrub: false,
          trigger: '.sec-2',
          start: 'top top',
          end: '+=800',
          onUpdate: (self) => progressTo(self.progress),
        },
      });
    });

    // 배경, 텍스트 고정 / 컨텐츠 변경시 배경 색상 변경
    var tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.sec-6',
        pin: true,
        scrub: true,
        start: 'top top',
        end: '+=4000 top',
      },
    });

    var tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: '.sec-6',
        scrub: true,
        start: 'top top',
        end: '+=4000 top',
      },
    });

    tl.to('.page-section.codelab', { duration: 10, opacity: 1 })
      .to('.page-section.fonts', { duration: 10, opacity: 1 })
      .to('.page-section.blog', { duration: 10, opacity: 1 })
      .to('.page-section.event', { duration: 10, opacity: 1 });

    tl2
      .to('.sec-6', {})
      .to('.sec-6', { backgroundImage: 'linear-gradient(to top, #ffffff 0%, #ffffff 40%, #6192eb 40%, #6192eb 100%)' })
      .to('.sec-6', { backgroundImage: 'linear-gradient(to top, #ffffff 0%, #ffffff 40%, #7ebba1 40%, #7ebba1 100%)' })
      .to('.sec-6', { backgroundImage: 'linear-gradient(to top, #ffffff 0%, #ffffff 40%, #f3b27e 40%, #f3b27e 100%)' });

    // 텍스트 뒷편에 고정, 스크린들만 스크롤 할 수 있게, 다 올라왔을 때는 자연스럽게 사라질 수 있도록
    var pageTl = gsap.timeline({
      scrollTrigger: {
        trigger: '.sec-8',
        pin: true,
        // markers: true,
        scrub: true,
        start: 'top +=100',
        end: '+=3000 top',
      },
    });
    pageTl
      .from('.row-screen.fi', { y: 200, opacity: 0, duration: 20 }, '+=1')
      .to('.row-screen.fi', { y: 0, opacity: 1 }, '+=1')
      .to('.row-screen.fi', { y: -10, opacity: 0, duration: 20 }, '+=1')

      .from('.row-screen.se', { y: 200, opacity: 0, duration: 20 }, '+=1')
      .to('.row-screen.se', { y: 0, opacity: 1 }, '+=1')
      .to('.row-screen.se', { y: -10, opacity: 0, duration: 20 }, '+=1')

      .from('.row-screen.th', { y: 200, opacity: 0, duration: 20 }, '+=1')
      .to('.row-screen.th', { y: 0, opacity: 1 }, '+=1');
  }
}

function detail35() {
  if (document.querySelector('.detail35')) {
    var swiper = new Swiper('.con-swiper', {
      slidesPerView: 'auto',
      loopedSlides: 3,
      centeredSlides: true,
      spaceBetween: 10,
      loop: true,
      autoplay: 4000,
      onSlideChangeStart: function (swiper) {
        $('.swiper-detail .detail-box').removeClass('on');
        $('.swiper-detail .detail-box').eq(swiper.realIndex).addClass('on');
      },

      breakpoints: {
        420: {
          slidesPerView: 'auto',
          loopedSlides: 3,
          centeredSlides: true,
          spaceBetween: 10,
          loop: true,
        },
        1: {
          slidesPerView: 1,
          loopedSlides: false,
        },
      },
    });
  }
}

var ww = $(window).width();
var mySwiper = undefined;

function initSwiper() {
  if (document.querySelectorAll('.detail41').length > 0) {
    if (ww > 420 && mySwiper == undefined) {
      mySwiper = new Swiper('.swiper-forum', {
        slidesPerView: 3,
        spaceBetween: 30,
        slidesPerGroup: 3,
        loopFillGroupWithBlank: true,
        loop: true,
        slideToClickedSlide: true,
        speed: 1800,
        autoplay: 3500,
        disabledInteraction: false,
        observer: true,
        observeParents: true,
      });
    } else if (ww <= 420 && mySwiper != undefined) {
      mySwiper.destroy(true, true);
      mySwiper = undefined;
    }
  }
}

initSwiper();

$(window).on('resize', function () {
  ww = $(window).width();
  initSwiper();
});

function detailPlay() {
  if (document.querySelector('.play') != null) {
    var controller = new ScrollMagic.Controller();
    $('.play').each(function () {
      var ourScene = new ScrollMagic.Scene({
        triggerElement: this.children[0],
        triggerHook: 0.9,
        reverse: false,
      })
        .setClassToggle(this, 'is-play')
        .addTo(controller)
        .on('enter', function (event) {
          $('.is-play video').trigger('play');
        });
    });
  }
}

$(function () {
  detailAni();
  document.addEventListener('swup:contentReplaced', detailAni);

  detailPlay();
  document.addEventListener('swup:contentReplaced', detailPlay);

  detailCardShow();
  document.addEventListener('swup:contentReplaced', detailCardShow);
  document.addEventListener('resize', detailCardShow);

  detail35();
  document.addEventListener('swup:contentReplaced', detail35);

  detail37();
  document.addEventListener('swup:contentReplaced', detail37);

  initSwiper();
  document.addEventListener('swup:contentReplaced', initSwiper);
});
