import Swup from 'swup';
import $ from 'jquery';
import ScrollMagic from 'scrollmagic';
import gsap from 'gsap';

//주소에 따른 작업(각 페이지별 html에 클래스값 들어가기위한 스크립트, 메인에서 풀페이지 스크립트 불러오고 다른페이지에선 초기화 되도록 작업)
function companyContentReplaced() {
  if (document.querySelector('html') != null) {
    if (window.location.pathname === '/company.html' || window.location.pathname === '/company') {
      console.log('includes(company.html)');
      $('html').addClass('company');
      $('html').removeClass('sub');
      $('html').removeClass('home');
      $('html').removeClass('project');
      $('html').removeClass('careers');
      $('html').removeClass('contact');
      $('html').removeClass('detail');
      $('html').removeClass('tech');
      $('html').removeClass('career');
      $('html').removeClass('people');
      $('html').removeClass('blog');
      $('html').removeClass('blog-single');
      $('html').removeClass('intro-ing');
      $('html').removeClass('detail4');
    }
  }
}

//컴퍼니 서비스 애니메이션(좌측 타이틀 스크롤시 바애니메이션용)
function companyTitleAni() {
  if (document.querySelector('.service-wrapper') != null) {
    var controller = new ScrollMagic.Controller();

    var tl = gsap.timeline();
    tl.to('.service-wrapper .main-title', 0, { css: { zIndex: '1' } });
    tl.to('.service-wrapper .main-title .bar', 0.3, { css: { transform: 'scaleX(1)' } }),
      tl.to('.service-wrapper .main-title', 0, { css: { color: '#0d0d0e' } }),
      tl.to('.service-wrapper .main-title', 0, { css: { opacity: '1' } }),
      tl.to('.service-wrapper .main-title .bar', 0, { transformOrigin: 'right' }),
      tl.to('.service-wrapper .main-title .bar', 0.3, { css: { transform: 'scaleX(0)' } });

    var scene = new ScrollMagic.Scene({
      triggerElement: '.service-wrapper',
      triggerHook: 0.7,
    })
      .setTween(tl)
      .addTo(controller);
  }
}

//컴퍼니 히스토리 플리커(컴퍼니 히스토리 10주년 고정 되었다 풀리는용)
function companyYear() {
  if (document.querySelector('.history-wrapper') != null) {
    $(document).ready(function () {
      var controller = new ScrollMagic.Controller();

      var scene = new ScrollMagic.Scene({
        triggerElement: '.history-wrapper',
        triggerHook: 0.1,
        duration: '130%',
      })
        .setPin('.history-wrapper .year')
        .addTo(controller);
    });
  }
}

//컴퍼니 히스토리 애니메이션(컴퍼니 히스토리 10주년 최하단내려갔을시 사라지는용)
function companyYearAni() {
  if (document.querySelector('.history-wrapper') != null) {
    $(document).ready(function () {
      var controller = new ScrollMagic.Controller();

      var tl = gsap.timeline();
      tl.to('.history-wrapper .year', 0.3, { css: { opacity: '0' } });

      var scene = new ScrollMagic.Scene({
        triggerElement: '.history-wrapper .hidden-line',
        triggerHook: 1,
      })
        .setTween(tl)
        .addTo(controller);
    });
  }
}

//모바일 더보기(모바일에서 히스토리 더보기 , 닫기 버튼액션용)
function historyMore() {
  if (document.querySelector('.history-wrapper') != null) {
    $(document).ready(function () {
      $('.btn-text-more').click(function () {
        if ($('.history-inner').hasClass('is-active') === false) {
          $('.history-inner').addClass('is-active');
        } else {
          $('.history-inner').removeClass('is-active');
        }
        $('.btn-text-more').text(function (i, text) {
          return text === '더보기' ? '닫기' : '더보기';
        });
      });
    });
  }
}

//컴퍼니 이미지 슬라이드(컴퍼니 인피니트스크롤러 플러그인 호출용)
function companyImageSlide() {
  if (document.querySelector('#companySlide') != null) {
    $(document).ready(function () {
      $('#companySlide').infiniteScroller({
        resetOnMouseOut: false,
        maxSpeed: 4,
        speed: 1,
      });
    });
  }
}

//무한 슬라이드 플러그인(수상배너용 플러그인)
function infiSlide() {
  if (document.querySelector('html') != null) {
    $(window).ready(function () {
      window.loaded = true;
    });
    $(function () {
      $.fn.infiniteslide = function (options) {
        //option
        var settings = $.extend(
          {
            speed: 100,
            direction: 'left',
            pauseonhover: true,
            responsive: false,
            clone: 1,
          },
          options,
        );

        var setCss = function (obj, direction) {
          $(obj).wrap('<div class="infiniteslide_wrap"></div>').parent().css({
            overflow: 'hidden',
          });

          if (direction == 'up' || direction == 'down') {
            var d = 'column';
          } else {
            var d = 'row';
          }

          $(obj)
            .css({
              display: 'flex',
              flexWrap: 'nowrap',
              alignItems: 'center',
              '-ms-flex-align': 'center',
              flexDirection: d,
            })
            .children()
            .css({
              flex: 'none',
              display: 'block',
            });
        };

        var setClone = function (obj, clone) {
          var $clone = $(obj).children().clone().addClass('infiniteslide_clone');
          var i = 1;
          while (i <= clone) {
            $clone.clone().appendTo($(obj));
            i++;
          }
        };

        var getWidth = function (obj) {
          var w = 0;
          $(obj)
            .children(':not(.infiniteslide_clone)')
            .each(function (key, value) {
              w = w + $(this).outerWidth(true);
            });
          return w;
        };
        var getHeight = function (obj) {
          var h = 0;
          $(obj)
            .children(':not(.infiniteslide_clone)')
            .each(function (key, value) {
              h = h + $(this).outerHeight(true);
            });
          return h;
        };

        var getSpeed = function (l, s) {
          return l / s;
        };
        var getNum = function (obj, direction) {
          if (direction == 'up' || direction == 'down') {
            var num = getHeight(obj);
          } else {
            var num = getWidth(obj);
          }
          return num;
        };

        var getTranslate = function (num, direction) {
          if (direction == 'up' || direction == 'down') {
            var i = '0,-' + num + 'px,0';
          } else {
            var i = '-' + num + 'px,0,0';
          }
          return i;
        };

        var setAnim = function (obj, id, direction, speed) {
          var num = getNum(obj, direction);
          if (direction == 'up' || direction == 'down') {
            $(obj)
              .parent('.infiniteslide_wrap')
              .css({
                height: num + 'px',
              });
          }
          var i = getTranslate(num, direction);

          $(obj).attr('data-style', 'infiniteslide' + id);

          var css =
            '@keyframes infiniteslide' +
            id +
            '{' +
            'from {transform:translate3d(0,0,0);}' +
            'to {transform:translate3d(' +
            i +
            ');}' +
            '}';
          $('<style />')
            .attr('id', 'infiniteslide' + id + '_style')
            .html(css)
            .appendTo('head');

          if (direction == 'right' || direction == 'down') {
            var reverse = ' reverse';
          } else {
            var reverse = '';
          }

          $(obj).css({
            animation: 'infiniteslide' + id + ' ' + getSpeed(num, speed) + 's linear 0s infinite' + reverse,
          });
        };
        var setStop = function (obj) {
          $(obj)
            .on('mouseenter', function () {
              $(this).css({
                animationPlayState: 'paused',
              });
            })
            .on('mouseleave', function () {
              $(this).css({
                animationPlayState: 'running',
              });
            });
        };

        var setResponsive = function (obj, direction) {
          var num = getNum(obj, direction);
          var i = getTranslate(num, direction);
          return i;
        };

        return this.each(function (key, value) {
          var $this = $(this);
          var num = Date.now() + Math.floor(10000 * Math.random()).toString(16);
          if (settings.pauseonhover == true) {
            setStop($this);
          }
          var _onload = function () {
            setCss($this, settings.direction);
            setClone($this, settings.clone);
            setAnim($this, num, settings.direction, settings.speed);

            if (settings.responsive) {
              $(window).on('resize', function () {
                var i = setResponsive($this, settings.direction);
                var styleid = $this.attr('data-style');
                var stylehtml = $('#' + styleid + '_style').html();

                var stylehtml_new = stylehtml.replace(
                  /to {transform:translate3d\((.*?)\)/,
                  'to {transform:translate3d(' + i + ')',
                );
                $('#' + styleid + '_style').html(stylehtml_new);
              });
            }
          };

          if (window.loaded) {
            _onload();
          } else {
            $(window).on('load', _onload);
          }
        });
      };
    });
  }
}

//어워드 슬라이드(수상배너용 플러그인 호출용)
function slideAwards() {
  if (document.querySelector('.awards-slide-wrapper') != null) {
    $(document).ready(function () {
      $('#slideAwards').infiniteslide({
        speed: 70,
        pauseonhover: false,
        responsive: true,
      });
    });
  }
}

//컴퍼니 헤더색변경(컴퍼니 뒷배경 흰색일시 로고색 변경용)
function companyLogoColor() {
  if (document.querySelector('.partner-warpper') != null) {
    $(document).on('scroll resize ready', function () {
      if ($('.page-section').hasClass('company') === true) {
        var divOffset = $('.partner-warpper').offset();
        var width_size = window.outerWidth;

        if (width_size >= 420) {
          if ($(document).scrollTop() > divOffset.top) {
            $('body').addClass('color-black');
          } else {
            $('body').removeClass('color-black');
          }
        } else {
          if ($(document).scrollTop() > divOffset.top - 158) {
            $('body').addClass('color-black');
          } else {
            $('body').removeClass('color-black');
          }
        }
      }
    });
  }
}

$(function () {
  companyContentReplaced();
  document.addEventListener('swup:contentReplaced', companyContentReplaced);
  companyTitleAni();
  document.addEventListener('swup:contentReplaced', companyTitleAni);
  companyYear();
  document.addEventListener('swup:contentReplaced', companyYear);
  companyYearAni();
  document.addEventListener('swup:contentReplaced', companyYearAni);
  historyMore();
  document.addEventListener('swup:contentReplaced', historyMore);
  companyImageSlide();
  document.addEventListener('swup:contentReplaced', companyImageSlide);
  infiSlide();
  document.addEventListener('swup:contentReplaced', infiSlide);
  slideAwards();
  document.addEventListener('swup:contentReplaced', slideAwards);
  companyLogoColor();
  document.addEventListener('swup:contentReplaced', companyLogoColor);
});
