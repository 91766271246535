import $ from 'jquery';

//이미지 필터링(스크롤시 보이는 이미지만 노출되도록 하는용)
function lazyImage() {
  if (document.querySelector('html') != null) {
    lazyload();
    window.onscroll = function (ev) {
      lazyload();
    };
    function lazyload() {
      var lazyImage = document.getElementsByClassName('lazy');
      for (var i = 0; i < lazyImage.length; i++) {
        if (elementInViewport(lazyImage[i]) - 500) {
          lazyImage[i].setAttribute('srcset', lazyImage[i].getAttribute('data-src'));
        }
      }
    }

    function elementInViewport(el) {
      var rect = el.getBoundingClientRect();
      return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
    }
  }
}

//디테일 슬라이드 lazy 이미지(디테일 슬라이드 이미지 호출용)
function swiperSlideImage() {
  if (document.querySelector('html') != null) {
    $('.swiper-slide').each(function () {
      var dataSrc = $(this).find('.lazy').attr('data-src');
      $(this).find('.lazy').attr('srcset', dataSrc);
    });
  }
}

$(function () {
  lazyImage();
  document.addEventListener('swup:contentReplaced', lazyImage);

  swiperSlideImage();
  document.addEventListener('swup:contentReplaced', swiperSlideImage);
});
