import Swup from 'swup';
import $ from 'jquery';

function blogContentReplaced() {
  if (document.querySelector('html') != null) {
    if (
      window.location.pathname === 'https://www.notion.so/e3c952b5ac6943c1bd54c33e1ef73e4a' ||
      window.location.pathname === '/blog'
    ) {
      $('html').addClass('blog');
      $('html').removeClass('sub');
      $('html').removeClass('home');
      $('html').removeClass('project');
      $('html').removeClass('careers');
      $('html').removeClass('company');
      $('html').removeClass('tech');
      $('html').removeClass('detail');
      $('html').removeClass('people');
      $('html').removeClass('career');
      $('html').removeClass('blog-single');
      $('html').removeClass('detail4');
      $('.gnb-category .gnb-item').removeClass('is-current');
      $('html').removeClass('intro-ing');
    }
  }
}

$(function () {
  blogContentReplaced();
  document.addEventListener('swup:contentReplaced', blogContentReplaced);
});
